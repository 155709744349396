/* src/components/StickyBottomButtons.css */
.sticky-bottom-buttons {
    position: fixed; /* Keep it in a fixed position */
    bottom: 0; /* Stick to the bottom of the screen */
    left: 0; /* Align to the left edge */
    right: 0; /* Align to the right edge */
    background: white; /* Background color */
    padding: 10px; /* Padding around the buttons */
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1); /* Shadow for better visibility */
    display: none; /* Hide by default */
    justify-content: space-evenly; /* Even spacing between buttons */
    align-items: center; /* Center vertically */
  }
  
  .button-item {
    display: flex;
    align-items: center; /* Align icon and text */
    cursor: pointer; /* Pointer cursor to indicate clickable */
  }
  
  .button-item span {
    font-size: 1em; /* Font size for the text */
  }
  
 
  .buttoncall {
    display: inline-flex;
    align-items: center; 
    cursor: pointer; 
    padding: 5px; 
    border: 1px solid #FF9E00; 
    border-Radius: 5px;
    margin-Right: 25px;
    margin-Top: 10px;
    margin-Left: -10px;
   
    color: white;
    background: #FF9E00;
    justify-content: center;
    width: 100%!important;
  }
  .width100{
    width: 100%!important;
  }