@media (max-width: 900px) {
     .slick-arrow, .menuDesktop{
    display: none!important;
}
.noEmailMobile{
    display: none;
}

.languageChangerJustMobile {
    display: block; /* Breaks onto a new line */
    text-align: center; /* Center align the text */
    margin-top: 10px; /* Optional: adds some spacing */
}
.displayNone{
    display: none!important;
}

.MenuIconNoDesktop{
    display: block!important;
    float: inline-end;
       
 }
.marginLeft20A{
    margin-left: 20px!important;
}
.marginRight20 {
        margin-top: -5px;
        margin-right: 20px!important;       
        font-size: 43px !important;
}

 .displayNoneDesktop {
    display: inline-block!important; /* Make LanguageSwitcher visible on mobile */
  }

  .MenuIconNoDesktop {
    display: inline-block; /* Make MenuIcon visible on mobile */
  }

  /* Ensure both elements are on the same line */
  .mobile-inline {
    display: block;
    /* vertical-align: middle; Keep them aligned vertically */
    flex: 1,!important;
  }



  .styleButtonMobileCall{
    background: #27af0a !important;
    color: #fff !important;
    margin: 10px 5px 0 0px !important;
    width: calc(50% - 25px) !important;
    display: inline-block;
  }
.styleButtonMobileWhatsupp
{
    background: #27af0a !important;
    color: #fff;
    margin: 10px 0 0 5px !important;
    width: calc(50% - 25px) !important;
    display: inline-block;
}


.phoneButtonContainer {
    flex-direction: row; /* Ensure the buttons stay on the same line */
    justify-content: space-between; /* Space them out */
    padding: 10px; /* Optional padding for mobile */
  }

  .phoneButtonContainer > div {
    width: 43%; /* Each button takes almost half the width */
  }

  .sliderOnMobile {
    margin-top: 45%;
  }

  .headerBarParent{
    position: absolute!important;
    background-color: black!important;
  }

  .sticky-bottom-buttons {
    display: flex; /* Show only on mobile devices */
  }
.width100{
    width: 100%!important;
}
#root > div.MuiContainer-root.MuiContainer-maxWidthLg.css-1oqqzyl-MuiContainer-root > div > div:nth-child(1) > div {
    width: 90%!important;
    margin-left: 2px!important;
    margin-top: 5px!important;
}
#root > div.MuiContainer-root.MuiContainer-maxWidthLg.css-1oqqzyl-MuiContainer-root > div > div:nth-child(2) > div {
    width: 90%!important;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px!important;
}
#root > div.MuiContainer-root.MuiContainer-maxWidthLg.css-1oqqzyl-MuiContainer-root > div > div > div:nth-child(1) > div{
    width: 95%!important;
    margin-top: 0px!important;
    margin-left: -5px!important;
}
#root > div.MuiContainer-root.MuiContainer-maxWidthLg.css-1oqqzyl-MuiContainer-root > div > div > div:nth-child(2) > div{
    width: 95%!important;

    margin-top: 0px!important;
    
}


/* Style pentru main component in homepage */
.two-column-layout {
    display: grid; /* Use CSS grid for the layout */
    grid-template-columns: 4fr; /* 1/4 and 3/4 column widths */
    gap: 10px; /* Optional gap between columns */
  }





.machinerypret div h1{
  text-align:center!important;

}































}


