/* src/components/TwoColumnLayout.css */
.two-column-layout {
    display: grid; /* Use CSS grid for the layout */
    grid-template-columns: 1fr 3fr; /* 1/4 and 3/4 column widths */
    gap: 10px; /* Optional gap between columns */
  }
  
  .column {
    padding: 10px; /* Optional padding */
    border: 1px solid #d7d7d7; /* Optional border for visibility */
    text-align: center; /* Center align text */
  }
  
  .column-left {
    background-color: #d7d7d7; /* Styling for the left column */
  }
  
  .column-right {
    background-color: #d7d7d7; /* Styling for the right column */
  }
  
  .root {
    background-color: black;
  }