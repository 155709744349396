    .slider {
      position: relative;
      width: 100%;
      overflow: hidden;
    }

    .slider-track {
      display: flex;
      transition: transform 0.5s ease-in-out;
    }

    .slide, .slide2{
      flex-shrink: 0;
      width: 100%;
      max-width: 300px;
      margin: 0 10px;
      flex-direction: column;
      margin-top: 100px;
      margin-bottom: 100px;
      margin-left: 50px;
    }

    .product-image img {
      width: 100%;
      height: 220px!important;
      border-radius: 8px;
      transition: transform 0.3s ease-in-out;
      object-fit: fill!important;
    }

    .product-info {
      padding: 10px;
       text-align: center; 
      background: #fff;
      border-radius: 0 0 8px 8px;
      /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
      width: 100%;
      max-width: 100%;
    }

    .product-info h3 {
      font-size: 18px;
      margin: 10px 0;
    }

    .product-info p {
      font-size: 16px;
      color: #333;
    }
    .slide2 {
      cursor: pointer;
    }
    .product-image:hover img {
      transform: scale(1.05);  /* Zoom-in effect */
    }

    /* Mobile Styles */
    @media (max-width: 768px) {
      .slider-track {
        display: block;
      }
      .slider{
        height: 300px;
        padding-top: 100px;
      }
      .slide {
        max-width: 100%;
        margin: 10px 0;
      }

      .product-info {
        padding: 8px;
      }
      .slider-track{
        display: flex;
        flex-shrink: 0;
        width: 100%;
        max-width: 300px;
        margin: 0 10px;
        flex-direction: row!important;
       
        
        
      }

      .slider-track23 {
        display: flex;
        width: 200%;
        animation: scroll 10s linear infinite; /* Faster speed for mobile */
    }


/* Keyframe for animation */
@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-150%); /* Move the slider by 100% to the left */
    }
    }
  }