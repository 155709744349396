.cookie-popup {
    position: fixed;
    bottom: 20px;
    left: 20px; /* Move to the left side */
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 300px; /* Fixed width for consistency */
    border-radius: 2%;
  }
  
  .cookie-popup-content {
    display: flex;
    flex-direction: column; /* Stack elements vertically for better mobile experience */
    align-items: flex-start; /* Align content to the left */
  }
  
  .cookie-popup-content p {
    margin: 0 0 10px 0; /* Add bottom margin for spacing */
  }
  
  .cookie-popup-content button {
    padding: 10px 20px;
    background-color: #ffcc00;
    color: black;
    border: none;
    cursor: pointer;
    align-self: flex-start; /* Align button to the left */
  }
  
  .cookie-popup-content button:hover {
    background-color: #ffcc00;
  }
  
  /* Media query for mobile devices */
  @media (max-width: 600px) {
    .cookie-popup {
        bottom: 10%;
        left: 10px;
        width: 88%;
        padding: 15px;
    }
  
    .cookie-popup-content {
      align-items: center; /* Center align for better appearance on small screens */
      text-align: center; /* Center text */
    }
  
    .cookie-popup-content p {
      padding-right: 0; /* Remove right padding */
      margin-bottom: 10px;
    }
  
    .cookie-popup-content button {
      width: 100%; /* Full width button for mobile */
    }
  }
  