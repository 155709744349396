/* src/components/PopupMenu.css */
.menu-links {
    display: flex;
    flex-direction: column; 
    align-items: flex-start; 
  }
  
  .menu-links > * {
    margin: 10px 0; 
  }
  .menu-links a{
    color: #27af0a;
    font-size: 25px;
    text-decoration: none;
    margin-bottom: 10px;
  }
  
  @media (min-width: 600px) {
    /* Ensure the popup is not full screen on larger devices */
    .MuiDialog-paperFullScreen {
      width: 50%; 
      max-width: 400px; 
  }
}
  