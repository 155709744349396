.search-input {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
  }
  
  .search-input input {
    padding: 8px;
    margin-right: 10px;
    border: 2px solid #27af0a;
    border-radius: 4px;
    width: 40%;
    height: 30px;
    
    align-items: center;
    text-align: center;
  }
  
  .search-input button {
    padding: 8px 12px;
    background-color: #27af0a;
    font-size: 16px;
    font-weight: 700;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    height: 47px;
    display: flex
;
    justify-content: center;
    align-items: center;
    width: 100px;
  }
  
  
  .search-input button:hover {
    background-color: #27af0a;
  }
  

  .search-input input:hover {
    border: 3px solid #27af0a;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Box shadow */

  }


  @media (max-width: 768px) {

    .search-input input {

        width: 65%;
        height: 22px;
     
      }
      .search-input button {
        height: 42px;
      }


  }