/* Container that hides the overflowing content */
.slider {
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
    background: white;
    margin-top: 20px;
}
  
  /* The track that contains all slides */
  /* Set width to 200% so that two sets of logos fit side by side */
  .slider-track {
    display: flex;
    width: 200%;
    animation: scroll 20s linear infinite;
  }
  
  /* Individual slide styling */
  /* Give each slide a fixed size (adjust as needed) */
  .slide {
    flex: 0 0 auto;
    width: 150px;   /* Set a fixed width */
    height: 150px;  /* Set a fixed height */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 30px; 
    margin-left: 30px;/* Adjust spacing between logos as needed */
  }
  
  /* Ensure images are forced to the same size */
  .slide img {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Ensures the image scales without distortion */
  }
  .slibrand {
    margin-top: 0px!important;
    margin-bottom: 0px!important;
    margin-left: 20px!important;
  }
  .slibrand img {
    width: 100%;
    height: 45px !important;
    object-fit: fill;
  }
  /* Responsive adjustments for mobile devices */
  @media (max-width: 768px) {
    .slide {
      margin-right: 10px;
      width: 100px;   /* Optionally adjust the size on mobile */
      height: 100px;
    }
  }
  
  /* Keyframes for infinite scrolling */
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      /* Translate by 50% to shift exactly one set of logos out of view */
      transform: translateX(-50%);
    }
  }
  