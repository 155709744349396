.classForTitleforProductsHomepage {
    color: black;

text-decoration: none;
padding: 14px;
line-height: 17px!important;
min-height: 17px!important;
width: 100%;
display: block;
background: #eee;
font-size: 20px!important;
font-weight: 500!important;
}
.marginTop10px{
    margin-top: 10px;
}