.two-column-layout {
    display: flex;
    flex-wrap: wrap;
  }
  
  .column-left {
    flex: 1;
    max-width: 450px;
    background: #fff;
  }
  
  .column-right {
    flex: 3;
    display: flex;
    flex-direction: column;
    background: #fff;
  }
  
  .vehicle-details {
    display: flex;
    flex-direction: column;
    padding-top:10px;
  }
  
  .vehicle-specifications {
    background: #27af0a;
    color:white;
    margin-bottom: 10px;
    font-weight: bold;
    padding: 5px;
    padding-left: 15px;
    text-align: left;
    text-transform: uppercase;
  }
  
  .sub-column-left, .sub-column-right {
    flex: 1;
    padding: 15px;
  }
  
  .sub-column-left {
    flex: 2;
  }
  
  .sub-column-right {
    flex: 3;
    display: flex;
    flex-direction: column;
  }
  .inlineDisplay{
    display: flex;
  }
  .productH1 {
    color: #313131;
    font-weight: bold;
    padding-bottom: 0px !important;
    margin-bottom: -10px;
    margin-top: -5px;
    font-weight: bold;
    font-family: 'Doppio One';
  }
  .wrapperPretsiWhatsupp{
    display: flex;
    margin-bottom: 10px;
  }
  .column-left{
    width: 45%;
    flex-wrap: wrap;
    display: flex !important;
    align-items: baseline;
  }

   .priceSpan {
    display: block;
    color: #e1ba00;
    font-weight: bold;
    font-size: 35px;
    margin-bottom: 2px;
   }
   .smallTax{
    font-size: 12px !important;
    line-height: 16px;
    margin-left: 5px;
   }
  .whatsuppProductPageButton{
    width: 55%;
  }
  .buttonWithHoverAndWhatsuppApiMessage{
    display: flex;
    gap: 17px;
    align-items: center;
    background: #27af0a;
    color: white !important;
    border-radius: 8px;
    padding: 10px 20px;
    margin: 0 20px;
    text-decoration: none !important;
    transition: .2s !important;
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
    cursor: pointer;
  }
  .buttonWithHoverAndWhatsuppApiMessage:hover {
    background: #27af0a !important;
    color: white !important;
    transition: .2s !important;
    cursor: pointer;
  }
  .productH2{
    color: #9d9d9d;
    font-size: 22px;
  }
  .body1{
    padding:10px;
    margin-top:5px;
  }
 .makethebestoffer{
    width: 46%!important;
    left: 25%;
    position: relative;
    right: 75%;
    background: #27af0a;

    color: white;

    border-radius: 7px;
    font-weight: bold!important;
    padding: 7px;
    font-size: 15px;
    font-weight: bolder;
    text-transform: uppercase;
  }
  #buttonsubmit {
    background: #27af0a;

    color: white;
  }
 #buttonsubmit:hover, .makethebestoffer:hover{
  background: #27af0a;
  color: white;
 }
 .css-xn6ruc>:not(style){
  width: 75ch!important;
}
.sizeFont{
  font-size: 12px;
}

 
.forceWidth .width100 {
  width: 100ch !important;
  text-align: justify;
}
  @media (max-width: 768px) {
    .two-column-layout {
      flex-direction: column;
    }
    .column-left{
      margin-top: 100px;
    }
    .column-left, .column-right {
      max-width: 100%;width: 100%;
    }
    .sub-column-left, .sub-column-right {
      max-width: 100%;
      flex: none;
    }
    
    
    .mainImageMobil {
      width: 100%!important;
      height: 100%!important;
    }
    .inlineDisplay {
      display: grid;
      
    }
    .column-left{
      display: none!important;
    }
    .vehicle-details{
      margin-top: 114px!important;
    }
    .whatsuppProductPageButton{
      width: 100%;
    }
    .buttonWithHoverAndWhatsuppApiMessage{
      margin:none!important;
      margin-right:0px!important;
      margin-left:0px!important;
      padding-top: 10px;
    }
    .wrapperPretsiWhatsupp{
      display: contents;
    }
    .productH1, .productH2{
      
      text-align: center;
      
    }
    .productH2{
      margin-top: 20px;
    margin-bottom: 5px;
    }
    .body1{
      padding:5px;
      text-align: left;
      margin-left: 30% !important;
        margin-top: 5px !important;
    }
    .priceSpan{
      
      text-align: center;
    }
    .MuiContainer-root {
      padding-right: 0px!important;
    }
    .descriptionPRoduct{
      padding:10px;
      font-size: 15px;
    }
    .makethebestoffer{
      width: 100%!important;
      left:0;
    }
    
    .forceWidth .width100{
      width: 55ch!important;
    }  .css-xn6ruc>:not(style){
      width: 25ch!important;
    }

  }


  #telephoneID , #emailID , #nameID, #priceID {
    margin-bottom: 5px!important;
  }
  
  .css-wb57ya-MuiFormControl-root-MuiTextField-root{
    padding-bottom: 10px!important;
  }
 
 
